<template>
  <div>
    <div class="card-header">
      <slot name="header">
        <h2 class="card-title">Current visits: {{selectedQueue.name}}</h2>
        <p class="card-subtitle" v-if="tableData.length!=0">Current waiting tickets: {{tableData.length}}</p>
      </slot>
    </div>


    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap card-body">
      <el-select
        class="select-primary mb-3 pagination-select"
        v-model="pagination.perPage"
        placeholder="Per page"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <base-input>
        <el-input
          type="search"
          class="mb-3 search-input"
          clearable
          prefix-icon="el-icon-search"
          placeholder="Search visits (Ticket, Name, Phone, Notes)"
          v-model="searchQuery"
          aria-controls="datatables"
        >
        </el-input>
      </base-input>

    </div>
    <el-table :data="queriedData">
      <el-table-column min-width="70" prop="ticket" label="Ticket"></el-table-column>
      <el-table-column min-width="90" label="Create Time">
        <div slot-scope="{row}" class="text-centre">
          <el-tooltip :content="convertToLocalTime(row.longCreationDate)"
                      :open-delay="300"
                      placement="top">
            <div>
              {{convertToLocalTime(row.longCreationDate)}}
            </div>
          </el-tooltip>
        </div>
      </el-table-column>
      <el-table-column min-width="110" prop="customer.firstName" :label="$t('liveTicketTable.customerColumn')"
                       :formatter=displayNameForTable></el-table-column>
      <el-table-column min-width="140" prop="customer.phoneNumber" label="Phone Number"></el-table-column>
      <el-table-column min-width="150" prop="customer.notes" label="Notes">
        <div slot-scope="{row}" class="text-centre">
          <a @click="updateNotes(row)" style="text-decoration: underline;">
            {{row.customer.notes}}
            <a v-if="row.customer.notes === ''">None</a>
            {{row.customer.notes}}
          </a>
        </div>
      </el-table-column>

      <el-table-column min-width="150"
                       header-align=""
                       label="Actions">
        <div slot-scope="{row}" class="text-centre actionButton">
          <el-tooltip content="Call Visit"
                      :open-delay="300"
                      placement="top"
                      v-if="showCallButton && row.callStationLogicId == null">
            <base-button type="info" class="animation-on-hover marRight" size="" @click="callVisit(row)">
              <i class="tim-icons icon-single-02"></i>
            </base-button>
          </el-tooltip>

          <el-tooltip content="Recall Visit"
                      :open-delay="300"
                      placement="top"
                      v-if="row.callStationLogicId != null">
            <base-button type="success" class="animation-on-hover marRight" size="" @click="recallVisit(row)">
              <i class="tim-icons icon-spaceship"></i>
            </base-button>

          </el-tooltip>

          <el-tooltip content="Delete Visit"
                      :open-delay="300"
                      placement="top"
                      v-if="isGod && row.callStationLogicId == null">
            <base-button type="danger" class="animation-on-hover" size="" @click="deleteVisit(row)">
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>

          <el-tooltip content="End Visit"
                      :open-delay="300"
                      placement="right"
                      v-if="row.callStationLogicId != null">
            <base-button type="warning" class="animation-on-hover marRight" size="" @click="endVisit(row)">
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>

          </el-tooltip>

        </div>
      </el-table-column>

      <el-table-column min-width="150"
                       header-align=""
                       label="Serving">
        <div slot-scope="{row}" class="text-centre actionButton">
          <p class="text-muted marRight" v-if="row.callStationLogicId != null">
            Serving by {{row.callStation.name}}
          </p>
        </div>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>

    <!-- Update Visit notes modal -->
    <modal :show.sync="modals.modal0"
           body-classes="p-0">
      <card type="secondary"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template slot="header">
          <h2 class="modal-title" id="exampleModalLabel">Update Notes for ticket '{{updatedRowNotes.ticket}}'</h2>
        </template>
        <div v-if="selects.preSelected.length == 0">
          <base-input
            type="text"
            name="notes"
            label="Notes"
            v-model="updatedRowNotes.notes"
          ></base-input>
        </div>
        <div v-else class="">
          <el-select class="select-danger col-md-12"
                     placeholder="Select a Timezone"
                     v-model="selects.selectedValue">
            <el-option value=''
                       label="Please select an action"
                       class="select-info"
                       key=''>
            </el-option>
            <el-option v-for="option in selects.preSelected"
                       class="select-danger"
                       :value="option.value"
                       :label="option.label"
                       :key="option.label">
            </el-option>
          </el-select>
        </div>

        <template slot="footer">
          <base-button type="primary" round @click="updateNoteField(updatedRowNotes)">Save changes</base-button>
          <base-button type="warning" round @click="modals.modal0 = false">Close</base-button>
        </template>
      </card>
    </modal>
  </div>
</template>

<script>
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import {AXIOS} from "../../../config/http-commons";
  import {EventBus} from "../../../config/Events";
  import {BasePagination, Modal} from 'src/components'
  import swal from 'sweetalert2'
  import Fuse from 'fuse.js';
  import moment from 'moment';

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Modal,
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option
    },
    props: {
      queueId: {
        type: Number,
        required: false
      },
      showCallButton: {
        type: Boolean,
        default: false,
        required: true
        },
    },
    methods: {
      convertToLocalTime(datetimeString) {
        console.log('datetimeString:' + datetimeString)
        console.log('datetimeString2:' + `${datetimeString} Z`)
        let localDate = new Date(`${datetimeString} Z`);
        console.log('returning:' + localDate.toLocaleTimeString())
        return localDate.toLocaleTimeString()
      },
      fillTableData() {
        console.debug('call fillTableData')

        if (this.selectedBranchId == -1) {
          return;
        }
        if (this.queueId === -1) {
          console.debug(' quickServe call visit')
          AXIOS.get('/rest/quickServe/branch/' + this.selectedBranchId + '/current')
            .then((response) => {
              this.$data.tableData = response.data;
            });
        } else if (this.queueId != null) {
          console.debug(' queue call visit')
          AXIOS.get('/rest/visits/branch/' + this.selectedBranchId + '/queue/' + this.queueId)
            .then((response) => {
              this.$data.tableData = response.data;
            });
        }
        console.debug(' fillTableData result')
        console.debug(this.$data.tableData)

      },
      deleteVisit(row) {
        swal.fire({
          title: 'Are you sure you want to delete Ticket \'' + row.ticket + '\'?',
          text: "You won't be able to revert this! This will effect statistics",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            AXIOS.delete('/rest/visits/branch/' + this.selectedBranchId + '/queue/' + this.queueId + '/visit/' + row.id).then(r => {
              this.fillTableData();
              swal.fire(
                'Deleted!',
                'Visit has been deleted.',
                'success'
              )
            });
          }
        })
      },
      callVisit(row) {
        console.debug('callVisitForQuickServe')
        this.$emit('visitIdToCall', row.id)
      },
      recallVisit(row) {
        AXIOS.get('/rest/visits/branch/' + this.selectedBranchId + '/recall/id/' + row.id).then(r => {
          this.$notify('Visit Recalled');
          this.$store.dispatch("setCurrentlyServing",
            r.data
          );
        });
      },
      endVisit(row) {
        AXIOS.post('/rest/visits/branch/' + this.selectedBranchId + '/queue/' + this.queueId + '/callstation/' + row.callStationLogicId + '/end', row)
          .then(r => {
            this.$store.dispatch("setCurrentlyServing",
              r.data
            );
          }).catch(error => {
          if (error.response.data.errorCodeMessage) {
            this.$notify({type: 'danger', message: error.response.data.errorCodeMessage});
          } else {
            this.$notify({type: 'danger', message: 'Error Calling visit'});
          }
        });
      },
      updateNotes(row) {
        this.updatedRowNotes = row;
        this.modals.modal0 = true;
      },
      updateNoteField(row) {
        this.modals.modal0 = false;
        if (this.selects.preSelected.length !== 0) {
          row.notes = this.selects.selectedValue;
        }

        AXIOS.post('/rest/quickServe/branch/' + this.selectedBranchId + '/visit/' + row.id + '/notes', JSON.stringify(row.parsedJson)).then(r => {
          this.$notify({type: 'info', message: 'Visit notes updated'});
          this.fillTableData();
        }).catch(e => {
          this.fillTableData();
        });
      },
      displayNameForTable(row, column, cellValue) {
        if (row.customer)
          return row.customer.firstName + " " + row.customer.lastName;
        else
          return "No customer"
      }
      ,
      timeouts() {
        this.fillTableData();
        this.timeout = setTimeout(this.timeouts, 10000);
      }
      ,
      manageSubs(selectBranchLogicId) {
        this.fillTableData();

        this.subscriptions.push(this.$stompClient.subscribe('/topic/call/' + selectBranchLogicId, this.onEvent, {id: 'callForLTTBranch' + selectBranchLogicId}));
        this.subscriptions.push(this.$stompClient.subscribe('/topic/add/' + selectBranchLogicId, this.onEvent, {id: 'addForLTTBranch' + selectBranchLogicId}));
        this.subscriptions.push(this.$stompClient.subscribe('/topic/end/' + selectBranchLogicId, this.onEvent, {id: 'endForLTTBranch' + selectBranchLogicId}));
      }
      ,
      onEvent(data) {
        this.fillTableData();
      }
    },
    computed: {
      /***
       * Returns a page from the searched data or the whole data. Search is performed in the watch section below
       */
      isGod() {
        return false;
      },
      queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.tableData.length;
      }
    },
    data() {
      return {
        modals: {
          modal0: false
        },
        tableData: [],
        selectedBranchId: -1,
        updatedRowNotes: {ticket: '', notes: 'test'},
        selects: {
          selectedValue: '',
          preSelected: []
        },
        propsToSearch: ['ticket', 'customer.firstName', 'customer.lastName', 'customer.phoneNumber', 'notes'],
        timeout: null,
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [10, 20, 30, 50],
          total: 0
        },
        searchQuery: '',
        searchedData: [],
        fuseSearch: null,
        subscriptions: [],
        selectedQueue: {name: ''},
      }
    },
    beforeDestroy() {
      if (this.selectedBranchId != -1) {
        this.subscriptions.forEach(s => s.unsubscribe())
      } else {
        this.tableData = [];
      }
      clearTimeout(this.timeout);
    },
    mounted() {
      EventBus.$on('selectBranchLogicId', selectBranchLogicId => {
        this.selectedBranchId = selectBranchLogicId;
        if (this.selectedBranchId != -1) {
          this.subscriptions.forEach(s => s.unsubscribe())
        } else {
          this.tableData = [];
        }

        this.manageSubs(selectBranchLogicId);

      });

      let branchId = this.$store.getters.getBranchID;
      if (branchId != -1) {
        this.selectedBranchId = branchId;
        this.manageSubs(branchId);
      }

      let preSelected = [];
      let commaList = this.$store.getters.csProperties['quickServe.preselectedNotes'].trim();
      if (commaList !== '') {
        commaList.split(',').forEach(e => {
          preSelected.push({value: e, label: e});
        });
        this.selects.preSelected = preSelected;
      }
      this.timeouts();
      this.fuseSearch = new Fuse(this.tableData, {
        threshold: 0.3,
        location: 0,
        distance: 800,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "ticket",
          "customer.notes",
          "customer.firstName",
          "customer.lastName",
          "customer.phoneNumber"
        ]
      });

      if (this.queueId !== undefined && this.queueId !== null)
        AXIOS.get('/rest/queues/' + this.queueId).then(r => {
          this.selectedQueue = r.data
        });
    },
    watch: {
      tableData: function () {
        console.log('table data updated')
        if (this.tableData != null)
          this.fuseSearch.setCollection(this.tableData);
      },
      queueId: function (newV, oldV) {
        this.queueId = newV;
        this.fillTableData();
        if (this.queueId !== undefined && this.queueId !== null)
          AXIOS.get('/rest/queues/' + this.queueId).then(r => {
            this.selectedQueue = r.data
          });
      },
      /**
       * Searches through the table data by a given query.
       * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
       * @param value of the query
       */
      searchQuery(value) {
        console.log('searching data for value:'+value)
        let result = this.tableData;
        console.log('data to search:'+this.tableData)
        if (value !== '') {
          result = this.fuseSearch.search(this.searchQuery);
        }
        this.searchedData = result;
      }
    }

  }
</script>
<style>
  .search-input {
    width: 320px !important;
  }

  .actionButton {
    display: flex !important;
  }

  .marRight {
    margin-right: 5px !important;
  }
</style>
