<template>
  <div>
    <div class="">
      <card type='card-danger' class="text-center" style="">
        <h1 class="card-title">Workstation</h1>
        <h4 class="card-text">Use this page to serve customers quickly and easily</h4>
      </card>
    </div>
    <div class="row">
      <div class="col-md-9">
        <card class="card-animation-on-hover" title="Workstation">
          <div class="row col-md-12">
            <h4 class="col-md-6">Select a queue to serve from
              <queue-select
                :branch-id="selectedBranchId"
                @emitNewQueueSelected="queueSelected">
              </queue-select>
            </h4>
            <h4 class="col-md-6">Select a Service Point to serve from
              <service-point-select
                :branch-id=" selectedBranchId"
                @newCallStationSelected="callStationSelected">
              </service-point-select>
            </h4>
          </div>
          <div class="col-md-12">
            <div class="row " style="padding-bottom: 20px;">
              <div class="col-12 text-center">
                <base-button :disabled="!isAllowedToDoCallActions" type="primary" class="animation-on-hover col-md-3"
                             size="lg" round block
                             @click="callNext"> {{isCurrentlyServing ? 'Recall visit' : 'Call Next'}}
                </base-button>
                <base-button :disabled="!isAllowedToDirectVisitAction" type="warning" class="animation-on-hover col-md-2"
                             size="lg" round block
                             @click="directVisit">Direct Visit
                </base-button>
                <base-button :disabled="!isAllowedToDoCallActions || !isCurrentlyServing" type="danger"
                             class="animation-on-hover col-md-3" size="lg" round block
                             @click="endVisit">End Visit
                </base-button>
                <base-button :disabled="!isAllowedToDoCallActions  || !isCurrentlyServing" type="info"
                             class="animation-on-hover col-md-3" size="lg"
                             round block
                             @click="modals.showTransferOption=true">Transfer Visit To Queue

                </base-button>
              </div>
            </div>

            <card class="bg-info" :title="currentlyServingTitle">
              <div v-if="this.webSocketVisitEvent.eventType === 'END' || this.webSocketVisitEvent.eventType !== null">
                <div class="row" v-if="webSocketVisitEvent.eventType !== 'END'">
                  <div class="col-md-6">
                    <h4>Current Ticket: {{webSocketVisitEvent.visit.ticket}}</h4>
                    <h4 v-if="webSocketVisitEvent.visit.customer">Customer First Name:
                      {{webSocketVisitEvent.visit.customer.firstName}}</h4>
                    <h4 v-if="webSocketVisitEvent.visit.customer">Customer Last Name:
                      {{webSocketVisitEvent.visit.customer.lastName}}</h4>
                  </div>
                  <div class="col-md-6">
                    <h4>Customer notified: {{webSocketVisitEvent.notify === true ? 'Yes' :'No'}} </h4>
                    <h4>Waiting Time: {{waitingTime}}</h4>
                    <h4>Transaction Time: {{secondsToHHMMSS(transactionTime)}}</h4>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </card>
      </div>
      <div class="col-md-3">
        <div class="row col-md-12">
          <card class="card-animation-on-hover" title="Additional Workstation Actions">
            <div class="btn-group-vertical col-md-12">
              <base-button :disabled="!isAllowedOpenCallStationAction" type="primary"
                           class="animation-on-hover verticalButtonFix" block round size="lg"
                           @click="openCallStation">
                Open
              </base-button>
              <base-button :disabled="!isAllowedCloseCallStationAction" type="primary"
                           class="animation-on-hover verticalButtonFix" block round size="lg"
                           @click="modals.showCloseReasons = true">
                Close with reason
              </base-button>
            </div>


          </card>

          <card class="card-animation-on-hover" title="Additional Screen Actions">


            <div class="row">
            </div>
            <div class="btn-group-vertical col-md-12">
                <base-button :disabled="!isAllowedCloseCallStationAction" type="primary"
                           class="animation-on-hover verticalButtonFix" block round size="lg"
                           @click="clearScreens">
                Reset Screens
              </base-button>
            </div>
          </card>

        </div>
        <div class="row col-md-12">
          <card class="card-animation-on-hover" title="Queue Overview" sub-title="Customers waiting per queue">
            <queue-overview :branch-id="selectedBranchId"></queue-overview>
          </card>
        </div>
      </div>
    </div>
    <card>
      <live-ticket-table
      :queue-id=this.selectedQueueId
      :show-call-button=this.showCallButton
      :visitid-to-call=this.visitIdToCall
      v-on:visitIdToCall="liveTicketTableCallVisit"
      >
      </live-ticket-table>
    </card>


    <modal :show.sync="modals.showTransferOption"
           body-classes="p-0">
      <card type="secondary"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template slot="header">
          <h2 class="modal-title" id="exampleModalLabel">Transfer ticket '{{webSocketVisitEvent.visit.ticket}}'</h2>
        </template>
        <div class="row">
          Please select a queue To transfer the select visit to:
          <queue-select
            :branch-id="selectedBranchId"
            @emitNewQueueSelected="transferQueueSelect">
          </queue-select>
        </div>
        <template slot="footer">
          <base-button type="primary" round @click="transferVisit">Transfer visit</base-button>
          <base-button type="warning" round @click="modals.showTransferOption = false">Close</base-button>
        </template>
      </card>
    </modal>

    <modal :show.sync="modals.showCloseReasons"
           body-classes="p-0">
      <card type="secondary"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template slot="header">
          <h2 class="modal-title">Reason for closing</h2>
        </template>
        <div class="row">
          <label> Please select a reason:</label>
        </div>
        <service-point-close-reason-select @reasonSelected="reasonSelected"
                                          class="row"></service-point-close-reason-select>

        <template slot="footer">
          <base-button type="primary" round @click="closeCallStation">Close CallStation</base-button>
          <base-button type="warning" round @click="modals.showCloseReasons = false">Close</base-button>
        </template>
      </card>
    </modal>


  </div>
</template>

<script>
  import LiveTicketTable from '../Components/custom/LiveTicketTable'
  import QueueOverview from '../Components/custom/QueueOverview'
  import QueueSelect from '../../components/custom/QueueSelect'
  import ServicePointSelect from '../../components/custom/ServicePointSelect'
  import ServicePointCloseReasonSelect from '../../components/custom/ServicePointCloseReasonSelect'
  import {AXIOS} from '../../config/http-commons'
  import {Modal} from 'src/components';

  export default {
    name: "Workstation",
    components: {
      LiveTicketTable,
      QueueSelect,
      ServicePointSelect,
      ServicePointCloseReasonSelect,
      QueueOverview,
      Modal
    },
    computed: {
      selectedBranchId() {
        return this.$store.getters.getBranchID;
      },
      isAllowedToDoCallActions() {
        return (this.selectedQueueId >= 1 || this.selectedQueueId === -1)
          && this.selectedQueueId != null
          && this.selectedBranchId >= 1
          && this.selectedCallStation != null
          && this.selectedCallStation >= 1
          && this.isAllowedCloseCallStationAction
          && this.isUserAtTheirDesk;
      },
      isAllowedToDirectVisitAction() {
          return (this.selectedQueueId >= 1 || this.selectedQueueId === -1)
          && this.selectedQueueId != null
          && this.selectedBranchId >= 1
          && this.selectedCallStation != null
          && this.selectedCallStation >= 1
          && this.isAllowedCloseCallStationAction
          && !this.isCurrentlyServing
          && this.isUserAtTheirDesk;
      },
      isAllowedAdditionalAction() {
        return ((this.selectedQueueId >= 1 || this.selectedQueueId === -1)
          && this.selectedQueueId != null
          && this.selectedBranchId >= 1
          && this.selectedCallStation != null
          && this.selectedCallStation >= 1)
        && this.isUserAtTheirDesk
      },
      isAllowedOpenCallStationAction() {
        return !this.selectedCallStationOpen
        && this.selectedCallStation != null
      },
      isAllowedCloseCallStationAction() {
        return this.selectedCallStationOpen
      },
      isCurrentlyServing() {
        return this.webSocketVisitEvent.eventType !== 'END'
        && this.webSocketVisitEvent.eventType != null
        && this.isUserAtTheirDesk;
      },
      currentlyServingTitle() {
        if (this.webSocketVisitEvent.eventType === 'END' || this.webSocketVisitEvent.eventType == null)
          return 'Not Currently Serving';
        return 'Currently Serving';
      },
      waitingTime() {
        let waitingTime = this.webSocketVisitEvent.visit.waitingTime;
        return this.secondsToHHMMSS(waitingTime);
      }
    },
    methods: {
      directVisit() {
        ///branch/{branchLogicId}/queue/{queueLogicId}/callstation/{callStationLogicId}/direct
        AXIOS.post('/rest/visits/branch/' + this.selectedBranchId + '/queue/' + this.selectedQueueId + '/callstation/' + this.selectedCallStation + '/direct')
          .then(r => {
            this.webSocketVisitEvent = r.data;
            let ticket = this.webSocketVisitEvent.visit.ticket
            this.$notify({type: 'info', message: 'Ticket ' + ticket + ' called!'});
          }).catch(error => {
          if (error.response.data.errorCodeMessage) {
            this.$notify({type: 'danger', message: error.response.data.errorCodeMessage});
          } else {
            this.$notify({type: 'danger', message: 'Error Calling visit'});
          }
        });
        this.showCallButton = false
      },
      queueSelected(event) {
        console.log('queueSelected event' + event)
        console.log('queueSelected event.id' + event.id)
        console.log('queueSelected event.logicId' + event.logicId)

        console.log('queueSelected event' + JSON.stringify(event, null, 2))

        this.selectedQueueId = event.logicId;
        this.checkIfEverythingSelected()
      },
      getCallStationSessionsForBranch(branchLogicId) {
        return AXIOS.get('/rest/callstations/session/branch/' + branchLogicId)
            .then(response => {
                return response.data;
            });
      },
      checkIfCallStationOpen(branchLogicId, callStationLogicId) {
        console.log('called checkIfCallStationOpen with branchLogicId:' + branchLogicId + ' callStationLogicId: ' + callStationLogicId);
        this.getCallStationSessionsForBranch(branchLogicId).then(sessions_data => {
          this.callStationSessions = sessions_data;
          console.log(Object.keys(sessions_data).length);
          if (Object.keys(sessions_data).length === 0) {
            this.selectedCallStationHasActiveSession = false;
          }
          else {
              sessions_data.filter(el => {
                  if (el.callstationLogicId == callStationLogicId) {
                      console.log("CallStation has an active session");
                      this.selectedCallStationHasActiveSession = true;
                  }
              })
          }
        })
      },
      callStationSelected(event) {
        this.selectedCallStation = event;
        this.checkIfCallStationOpen(this.selectedBranchId, this.selectedCallStation);
        this.checkIfEverythingSelected()
      },
      checkIfEverythingSelected() {
        console.log('checkIfEverythingSelected called');
        if (this.selectedCallStation != null && this.selectedQueueId != null && this.selectedBranchId != null && this.selectedCallStation >= 1) {
          // Get actively serving visit
          AXIOS.get('/rest/visits/branch/' + this.selectedBranchId + '/queue/' + this.selectedQueueId + '/callstation/' + this.selectedCallStation)
            .then(response => {
              console.log('checkIfEverythingSelected response.status:')
              console.log(response);
              if (response.status === 201) {
                if (response.data.errorCode === 1002) {
                  // Visit not found, meaning no one logged in
                  console.log('Visit not found, meaning no one logged in response.data.errorCode:'+ response.data.errorCode);
                  this.webSocketVisitEvent = {
                    visit: {
                      ticket: '01',
                      customer: {firstName: '', lastName: ''},
                    },
                    notify: true,
                    eventType: null
                  };
                  this.isUserAtTheirDesk = true;
                } else if (response.data.errorCode === 1004) {
                  //Meaning callstation is in use by someone else
                  this.isUserAtTheirDesk = false;
                  this.$notify({type: 'warning', message: 'CallStation logged in by another user'});
                  console.debug('CallStation logged in by another user, response.data.errorCode:'+ response.data.errorCode);
                } else if (response.data.errorCode === 1008) {
                  // Meaning the user is currently logged into another desk.
                  this.$notify({type: 'danger', message: 'You are logged into another CallStation'});
                  this.isUserAtTheirDesk = false;
                  console.debug('User is currently logged into another desk, response.data.errorCode:'+ response.data.errorCode);
                  // TODO not allow to do anything till they go back to there own desk
                } else if (response.data.errorCode === 1009) {
                  // Meaning the Callstation currently has no one logged in.
                  this.$notify({type: 'warning', message: 'You are not logged into CallStation'});
                  console.debug('User is not currently logged into Callstation, response.data.errorCode:'+ response.data.errorCode);
                  this.isUserAtTheirDesk = false;
                  // TODO not allow to do anything till they go back to there own desk
                } else {
                  console.debug('Unknown error!, response.data.errorCode:'+ response.data.errorCode);
                }

              } else {
                // all good, get the visit and push it to the front end for display
                console.debug('All good, user is logged into current Callstation, response.status:'+ response.status);
                this.webSocketVisitEvent = response.data;
                this.selectedCallStationOpen = true
                this.isUserAtTheirDesk = true;
              }
            });
        }
      },
      toggleTimer() {
        if (this.transactionTimer.isRunning) {
          clearInterval(this.transactionTimer.interval);
          this.transactionTime = 0
        } else {
          this.transactionTimer.interval = setInterval(this.incrementTime, 1000);
        }
        this.transactionTimer.isRunning = !this.transactionTimer.isRunning
      },
      incrementTime() {
        this.transactionTime = parseInt(this.transactionTime) + 1;
      },
      callNext() {
        console.debug('callNext')
        AXIOS.get('/rest/visits/branch/' + this.selectedBranchId + '/queue/' + this.selectedQueueId + '/callstation/' + this.selectedCallStation + '/next')
          .then(r => {
            this.webSocketVisitEvent = r.data;
            if (this.webSocketVisitEvent.eventType === 'CALL') {
              let ticket = this.webSocketVisitEvent.visit.ticket
              this.$notify({type: 'info', message: 'Ticket ' + ticket + ' called!'});
            }
            if (this.webSocketVisitEvent.eventType === 'RECALL') {
              let ticket = this.webSocketVisitEvent.visit.ticket
              this.$notify({type: 'info', message: 'Ticket ' + ticket + ' recalled'});
            }
          }).catch(error => {
          if (error.response.data.errorCodeMessage) {
            this.$notify({type: 'danger', message: error.response.data.errorCodeMessage});
          } else {
            this.$notify({type: 'danger', message: 'Error Calling visit'});
          }
        });
        this.toggleTimer();
        this.showCallButton = false
      },
      liveTicketTableCallVisit(visitID) {
        console.debug('call liveTicketTableCallVisit id:' + visitID)

        AXIOS.get('/rest/visits/branch/' + this.selectedBranchId + '/queue/' + this.selectedQueueId + '/callstation/' + this.selectedCallStation + '/call/id/' + visitID )
          .then(r => {
            this.webSocketVisitEvent = r.data;
            if (this.webSocketVisitEvent.eventType === 'CALL') {
              let ticket = this.webSocketVisitEvent.visit.ticket
              this.$notify({type: 'info', message: 'Ticket ' + ticket + ' called!'});
            }
            if (this.webSocketVisitEvent.eventType === 'RECALL') {
              let ticket = this.webSocketVisitEvent.visit.ticket
              this.$notify({type: 'info', message: 'Ticket ' + ticket + ' recalled'});
            }
          }).catch(error => {
          if (error.response.data.errorCodeMessage) {
            this.$notify({type: 'danger', message: error.response.data.errorCodeMessage});
          } else {
            this.$notify({type: 'danger', message: 'Error Calling visit'});
          }
        });
        this.toggleTimer();
        //this.showCallButton = false
      },
      endVisit() {
        console.debug('call endVisit')
        AXIOS.post('/rest/visits/branch/' + this.selectedBranchId + '/queue/' + this.selectedQueueId + '/callstation/' + this.selectedCallStation + '/end', this.webSocketVisitEvent.visit)
          .then(r => {
            console.log('endVisit REST response:')
            console.log(r)
            this.checkIfEverythingSelected();
            this.webSocketVisitEvent = r.data;
          }).catch(error => {
          if (error.response.data.errorCodeMessage) {
            this.$notify({type: 'danger', message: error.response.data.errorCodeMessage});
          } else {
            this.$notify({type: 'danger', message: 'Error Calling visit'});
          }
        });
        this.showCallButton = true
      },
      transferVisit() {
        console.debug('transferVisit')
        let transferParams = JSON.stringify({
          fromQueueLogicId: this.selectedQueueId,
          toQueueLogicId: this.transferOptions.toQueueLogicId,
        });
        console.debug('transferParams:'+transferParams)
        console.debug('trying to transfer:'+'/rest/visits/branch/' + this.selectedBranchId + '/queue/' + this.selectedQueueId + '/callstation/' + this.selectedCallStation + '/transfer')
        AXIOS.post('/rest/visits/branch/' + this.selectedBranchId + '/queue/' + this.selectedQueueId + '/callstation/' + this.selectedCallStation + '/transfer',
          transferParams)
          .then(r => {
            this.$notify({type: 'info', message: 'Ticket ' + r.data.ticket + ' transfer'});
            this.checkIfEverythingSelected();
            this.modals.showTransferOption = false
          }).catch(error => {
          if (error.response.data.errorCodeMessage) {
            this.$notify({type: 'danger', message: error.response.data.errorCodeMessage});
          } else {
            this.$notify({type: 'danger', message: 'Error transferring visit'});
          }
        });
        this.showCallButton = true
      },
      openCallStation() {
        console.debug('openCallStation')
        AXIOS.put('/rest/callstations/session/branch/' + this.selectedBranchId + '/callstation/' + this.selectedCallStation + '/start').then(r => {
          this.$notify({type: 'info', message: 'Call Station Opened'});
        }).then(r => {
            this.selectedCallStationOpen = true;
            this.checkIfEverythingSelected();
            console.debug('openCallStation about to notify, Callstation ' + this.selectedCallStation + ' opened!')
            console.debug('openCallStation about to notify, Callstation ' + this.selectedCallStation + ' opened!')
            //let event = this.webSocketVisitEvent.callStation.eventType
            this.$notify({type: 'info', message: 'Callstation ' + this.selectedCallStation + ' opened!'});
            }).catch(error => {
          if (error.response.data.errorCodeMessage) {
            this.$notify({type: 'danger', message: error.response.data.errorCodeMessage});
          } else {
            this.$notify({type: 'danger', message: 'Error Opening Call Station'});
          }
        });
        //ToDo this is where we need to place the message to warn the queue has been opened
        this.showCallButton = true
      },
      clearScreens() {
        console.debug('clearScreens')
        AXIOS.get('/rest/settings/refresh').then(r => {
          this.$notify({type: 'info', message: 'Screen refresh'});
          this.modals.clearScreens = false;
        }).then(r => {
            this.clearScreens = false;
            console.debug('clearScreens about to notify')
        });
        //ToDo this is where we need to place the message to warn the queue has been closed
        this.showCallButton = false
      },
      closeCallStation() {
        console.debug('closeCallStation')
        console.debug('closeCallStation is there a current visit to close webSocketVisitEvent:')
        console.debug(this.webSocketVisitEvent.visit)
        this.webSocketVisitEvent.visit
        if (this.webSocketVisitEvent.visit.ticket != '01'){
          console.debug('Current visit active so we will close now')
          this.endVisit();
        }

        AXIOS.delete('/rest/callstations/session/branch/' + this.selectedBranchId + '/callstation/' + this.selectedCallStation + '/end?reasonId=' + this.closeReasonId).then(r => {
          this.$notify({type: 'info', message: 'Call Station closed'});
          this.modals.showCloseReasons = false;
        }).then(r => {
            this.selectedCallStationOpen = false;
            console.debug('closeCallStation about to notify, Callstation ' + this.selectedCallStation + ' closed!')
        });
        //ToDo this is where we need to place the message to warn the queue has been closed
        this.showCallButton = false
      },
      transferQueueSelect(event) {
        console.debug('transferQueueSelect')
        this.transferOptions.toQueueLogicId = event.logicId;
      },
      reasonSelected(reason) {
        console.debug('reasonSelected')
        this.closeReasonId = reason;
      },
      secondsToHHMMSS(seconds) {
        let d = Number(seconds);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        let mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        let sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay;
      },
      getDefaultParams() {
        console.log('getDefaultParams')
        this.defaultBranchId = this.$store.getters.getBranchID;
        console.debug('defaultBranchId:' + this.defaultBranchId)
        AXIOS.get('/rest/branch/' + this.defaultBranchId).then(r => {
            let xtra = JSON.parse(r.data.branchProperties);
            console.log('branchProperties:' + xtra)
            this.defaultQueue.id = xtra.defaultQueueId;
            this.defaultQueue.logicId = xtra.defaultQueueLogicId;
            this.defaultQueue.name = xtra.defaultQueueName;
            this.defaultCreateStation.logicId = xtra.defaultCreateStationLogicId;
            this.defaultCreateStation.name = xtra.defaultCreateStationName;
            this.defaultCreateStation.printerMac = xtra.defaultCreateStationPrinterMac;
          });
      },
      printerOutOfPaper() {
        console.log('printerOutOfPaper')
        this.$notify({type: 'info', message: 'Printer has run out of paper!'});
      }
    },
    beforeDestroy() {
      if (this.selectedBranchId != -1) {
        this.$stompClient.unsubscribe('/topic/add/' + this.selectedBranchId);
        this.$stompClient.unsubscribe('/topic/call/' + this.selectedBranchId);
      } else {
        this.tableData = [];
      }
    },
    data: function () {
      return {
        modals: {
          showTransferOption: false,
          showCloseReasons: false,
          showClearScreens: false
        },
        webSocketVisitEvent: {
          visit: {
            ticket: '01',
            customer: {firstName: '', lastName: ''},
          },
          callStation: {
            eventType: null
          },
          notify: true,
          eventType: null
        },
        transactionTime: 0,
        selectedQueueId: null,
        selectedCallStation: null,
        selectedCallStationHasActiveSession: false,
        selectedCallStationOpen: false,
        transactionTimer: {
          isRunning: false,
          interval: null
        },
        transferOptions: {
          toQueueLogicId: null
        },
        closeReasonId: null,
        isUserAtTheirDesk: false,
        defaultBranchId: -999,
        defaultQueue: {
          id: 0,
          logicId: 0,
          name: ''
        },
        defaultCreateStation: {
          logicId: 0,
          name: '',
          printerMac: ''
        },
        showCallButton: false,
        visitIdToCall: -1,
      }
    },
    created() {
    },
    mounted() {
      this.getDefaultParams();
      this.$stompClient.subscribe('/topic/printerOutOfPaper/' + this.defaultBranchId, (data) => {
        this.printerOutOfPaper(data.body);
      }, {id: 'statEnd'});
    }
  }
</script>

<style scoped>
  .verticalButtonFix {
    margin: 0;
  }
</style>
