<template>
  <div>
    <div class="">
      <card type='card-danger' class="text-center" style="">
        <h1 class="card-title">Reception</h1>
        <h4 class="card-text">Use this page to create visits and tickets quickly and easily</h4>
      </card>
    </div>

    <div class="row">
      <div class="col-md-5">
        <card class="card-animation-on-hover" title="Customer Information"
              sub-title="Enter customer information to stage">
          <div class=" card-body">
            <div class="row">
              <div class="col-md-6">
                <base-input label="Customer First Name *"
                            type="text"
                            placeholder="First Name"
                            v-model="customerCreate.firstName"/>
                <base-input label="Customer Email Address"
                            type="email"
                            placeholder="Email Address"
                            v-model="customerCreate.emailAddress"/>
                <label>Visit Phone Number</label>
                <vue-tel-input id="receptionVueTelInput"
                               v-model="customerCreate.phoneNumber"
                               :disabledFetchingCountry=true
                               :preferredCountries="[ 'gb', 'ie','us']"
                               :defaultCountry="$t('vuePhoneNumber.defaultCountry')">
                </vue-tel-input>

              </div>

              <div class="col-md-6">
                <base-input label="Customer Last Name"
                            type="text"
                            placeholder="Last Name"
                            v-model="customerCreate.lastName"/>

                <base-input label="Customer Account Name"
                            type="text"
                            placeholder="Account Number"
                            v-model="customerCreate.accountNumber"/>
                <base-input label="Customer Notes"
                            type="text"
                            placeholder="Notes"
                            v-model="customerCreate.notes"/>
              </div>

            </div>
            <base-input label="Custom Text Message"
                            type="custom_sms_message"
                            placeholder="Custom Text Message"
                            v-model="customerCreate.custom_sms_message"/>

            <div class="row">
              <div class="col-md-6">
                <base-button type="primary" class="animation-on-hover" round block @click="saveCustomerCreateBtn">Submit
                </base-button>
              </div>
              <div class="col-md-6">
                <base-button type="primary" class="animation-on-hover" round block @click="resetCustomerCreate">Clear
                </base-button>
              </div>
            </div>
          </div>
        </card>
      </div>


      <div class="col-md-4">
        <card class="card-animation-on-hover" title="Visit Create" sub-title="Select a queue to stage">

          <div class="card-body">
            <div class="row">

              <create-station-select class="col-md-9" :branch-id="defaultBranchId"
                                    :defaultCreateStationName="this.defaultCreateStation.name"
                                     @emitCreateStationObj="receiveCreateStationObj">
              </create-station-select>
              <div class="col-md-3">
                <h6 class="">Print Ticket</h6>
                <base-switch v-model="switches.printTicket" :disabled="isPrintButtonDisabled">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </base-switch>
              </div>
            </div>


            <!--
            <div class='row' v-for="queue in queues">
              <base-button block round type="primary" class="row animation-on-hover" @click="stageQueue(queue)"
                           :disabled="queue.logicId<=-1 || (createStationObj == null || createStationObj.logicId === -1)">
                {{queue.name}}
              </base-button>
            </div>
            -->

            <queue-select :branch-id="defaultBranchId"
                          :defaultCreateQueueName="this.defaultQueue.name"
                          @emitNewQueueSelected="newQueueSelected"></queue-select>

          </div>
        </card>
      </div>


    </div>
  </div>
</template>

<script>
  import {AXIOS} from "../../config/http-commons";
  import {BaseSwitch} from 'src/components';
  import CreateStationSelect from 'src/components/custom/CreateStationSelect'
  import QueueSelect from 'src/components/custom/QueueSelect'
  import VueTelInput from 'vue-tel-input'
  import 'vue-tel-input/dist/vue-tel-input.css';


  export default {
    name: "Reception",
    components: {BaseSwitch, CreateStationSelect, QueueSelect, VueTelInput},
    methods: {
      debug(item) {
        console.log(item)
      },
      receiveCreateStationObj(value) {
        console.log('receiveCreateStationObj:' + value)
        this.createStationObj = value;
        this.switches.printTicket = this.createStationObj.printerMac !== null;
      },

      newQueueSelected(value)
      {
        console.log('main page newQueueSelected:' + value)
        this.createQueue = value
        //this.createQueue.name = value;
      },

      saveCustomerCreateBtn() {
        //data.createQueue = {}


        console.log('checking queue')
        if (this.createQueue.logicId) {
          console.log('checking queue using newly selected item, this.createQueue:' + this.createQueue);
        } else {
          this.createQueue = {
            id: this.defaultQueue.id,
            logicId: this.defaultQueue.logicId,
            name: this.defaultQueue.name
            };
          console.log('using default Queue, this.defaultQueue:' + this.defaultQueue);
        }

        console.log('checking createStation')
        if (this.createStationObj) {
          console.log('checking createStation using newly selected item, this.createStationObj:' + this.createStationObj);
        } else {
          this.createStationObj = {
            logicId: this.defaultCreateStation.logicId,
            id: this.defaultCreateStation.id,
            name: this.defaultCreateStation.name
          };
          console.log('used default CreateStation, this.createStationObj:' + this.createStationObj);
        }

        let data = {
          createStationLogicId: this.createStationObj.logicId,
        };
        console.log('starting saveCustomerCreateBtn - save data:' + data)

        console.log('checking stagedArea')
        data.customer = {
          firstName: this.customerCreate.firstName,
          lastName: this.customerCreate.lastName,
          notes: this.customerCreate.notes,
          phoneNumber: this.customerCreate.phoneNumber,
          emailAddress: this.customerCreate.emailAddress,
          customJsonProperties: JSON.stringify({
            accountNumber: this.customerCreate.accountNumber,
            custom_sms_message: this.customerCreate.custom_sms_message
          }),
        }

        if (this.customerCreate.firstName === '') {
          this.stagedArea.customer = this.customerCreate;
          this.resetCustomerCreate();
        }


        if (this.customerCreate.custom_sms_message === '') {
          this.customerCreate.custom_sms_message = this.defaultSmsCreateMessage;
        }

        console.log('ready to create ticket');
        console.log('ready to create ticket - this.defaultBranchId:' + this.defaultBranchId);
        console.log('ready to create ticket - this.createQueue.id:' + this.createQueue.id);
        console.log('ready to create ticket - this.createQueue.logicId:' + this.createQueue.logicId);
        console.log('ready to create ticket - this.createQueue.name:' + this.createQueue.name);
        console.log('ready to create ticket - + this.stagedArea.print.toString():' + this.switches.printTicket.toString());
        console.log('ready to create ticket - data:' + data);

        AXIOS.post('/rest/visits/branch/' + this.defaultBranchId + '/queue/' + this.createQueue.logicId + '/add?printTicket=' + this.switches.printTicket.toString(), data).then(r => {
          this.$notify({message: 'Created ticket \'' + r.data.left.ticket + '\' for \'' + this.createQueue.name + '\''});
          this.resetCustomerCreate();
        });

      },
      stageQueue(queue) {
        this.stagedArea.queue = queue;
        this.stagedArea.print = this.switches.printTicket;
        this.stagedArea.createStation = this.createStationObj;
      },
      createByButton() {
        data.createQueue = {}
        let data = {
          createStationLogicId: this.stagedArea.createStation.logicId,
        };
        if (this.stagedArea.customer.firstName !== "") {
          data.customer = {
            firstName: this.stagedArea.customer.firstName,
            lastName: this.stagedArea.customer.lastName,
            notes: this.stagedArea.customer.notes,
            phoneNumber: this.stagedArea.customer.phoneNumber,
            emailAddress: this.stagedArea.customer.emailAddress,
            customJsonProperties: JSON.stringify({
              accountNumber: this.stagedArea.customer.accountNumber
            }),
          }
        }

        AXIOS.post('/rest/visits/branch/' + this.defaultBranchId + '/queue/' + this.stagedArea.queue.logicId + '/add?printTicket=' + this.stagedArea.print.toString(), data).then(r => {
          this.$notify({message: 'Created ticket \'' + r.data.left.ticket + '\' for \'' + this.stagedArea.queue.name + '\''});
          this.resetCustomerCreate();
        });

      },
      resetCustomerCreate() {
        this.customerCreate = {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          emailAddress: '',
          accountNumber: '',
          custom_sms_message: '',
          notes: ''
        }
      },
      getDefaultParams() {
        this.defaultBranchId = this.$store.getters.getBranchID;
        console.log('defaultBranchId:');
        console.log(this.defaultBranchId);

        AXIOS.get('/rest/branch/' + this.defaultBranchId).then(r => {
            let xtra = JSON.parse(r.data.branchProperties);
            this.defaultQueue.id = xtra.defaultQueueId;
            this.defaultQueue.logicId = xtra.defaultQueueLogicId;
            this.defaultQueue.name = xtra.defaultQueueName;

            console.log('xtra.defaultCreateStationLogicId');
            console.log(xtra.defaultCreateStationLogicId);
            this.defaultCreateStation.logicId = xtra.defaultCreateStationLogicId;

            console.log('xtra.defaultCreateStationName');
            console.log(xtra.defaultCreateStationName);
            this.defaultCreateStation.name = xtra.defaultCreateStationName;

            console.log('xtra.defaultCreateStationPrinterMac');
            console.log(xtra.defaultCreateStationPrinterMac);
            this.defaultCreateStation.printerMac = xtra.defaultCreateStationPrinterMac;

            console.log('branchProperties:' + r.data.branchProperties);

            AXIOS.get('/rest/queues/' + this.defaultQueue.logicId).then(r => {
              console.log('queue response:');
              console.log(r);
              this.defaultSmsCreateMessage = r.smsCreateMessage;
            });
          });


      },
      getCustomSmsMessage(queueId){
        console.log('getCustomSmsMessage');
        console.log('this.defaultQueue.logicId:');
        console.log(this.defaultQueue.logicId);
        AXIOS.get('/rest/queues/' + queueId).then(r => {
            console.log('queue response:' + r);
            console.log('queue response smsCreateMessage:');
            console.log(r.data.smsCreateMessage);
            this.defaultSmsCreateMessage = r.smsCreateMessage;
          });
      }
    },
    computed: {
      // selectedBranchId() {

      //   if (branchId === -1 || branchId === "-1") {
      //     this.queues = [{
      //       name: 'Select A branch',
      //       id: -999
      //     }];
      //     return -1;
      //   }
      //   AXIOS.get('/rest/branch/' + branchId).then(r => {
      //     this.queues = r.data.queues;
      //   });
      //   return branchId;
      // },
      isPrintButtonDisabled() {
        if (this.createStationObj)
          if (this.createStationObj.printerMac !== null)
            return false;
        return true;
      }
    },
    watch:{
      createStationObj() {
        console.log('Main page - Create station has changed:' + this.createStationObj)
      },
      createQueue() {
        console.log('Main page - Create queue has changed:' + this.createQueue)
      }
    },
    data() {
      return {
        createStationObj: null,
        createQueue: {},
        customerCreate: {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          accountNumber: '',
          custom_sms_message: '',
          notes: ''
        },
        queues: [{
          name: 'Loading',
          id: -999
        }],
        defaultBranchId: -999,
        defaultQueue: {
          id: 0,
          logicId: 0,
          name: ''
        },
        defaultCreateStation: {
          logicId: 0,
          name: '',
          printerMac: ''
        },
        defaultSmsCreateMessage: "",
        switches: {
          printTicket: false
        }
      }
    },
    mounted() {
      console.log('mounted');
      let span = document.getElementById('receptionVueTelInput');
      let input = span.children[1]
      input.className = 'form-control';
      this.getDefaultParams();
    }
  };
</script>

<style scoped>
</style>
