<template>
  <div>
    <card type='card-danger' class="text-center" style="" title="Resource Admin">
      <h4 class="card-text">Use this page to globally manage resources</h4>
    </card>
    <div class="row">
      <div class="col-md-8">
        <card title="All Resources">
          <div class="card-body">
            <el-table :data="allResources.tableData">
              <el-table-column min-width="50" type="index"></el-table-column>
              <el-table-column min-width="120" prop="resourceCode" label="Res Code"></el-table-column>
              <el-table-column min-width="220" prop="resourceName" label="Name"></el-table-column>

              <el-table-column min-width="80" prop="resourceLogicId" label="Res Id"></el-table-column>
              <el-table-column min-width="150" prop="phoneNumber" label="Phone Number"></el-table-column>
              <el-table-column min-width="190" prop="emailAddress" label="Email"></el-table-column>
              <el-table-column min-width="200" prop="note" label="Note"></el-table-column>
              <el-table-column min-width="100" prop="enabled" :formatter="booleanCellRenderer" label="Enabled"></el-table-column>

              <el-table-column min-width="100" prop="resourceLogicId" label="Resource Id"></el-table-column>

              <el-table-column min-width="250"
                               header-align="center"
                               label="Actions"
                               class="text-center">
                <div slot-scope="{row}" class="text-centre ">
                  <el-tooltip content="Update Resource"
                              :open-delay="300"
                              placement="top">
                    <base-button type="info" size="" @click="showUpdateResourceModal(row)">
                      <i class="tim-icons icon-cloud-upload-94"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip content="Delete Resource"
                              :open-delay="300"
                              placement="top"
                              v-if="row.logicId != -1">
                    <base-button type="danger" size="" @click="deleteResource(row)">
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>

                </div>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
      <div class="col-md-4">
        <card title="Add Global Resources">
          <div class="card-body">
            <div class="row">
              <base-input class="col-sm-8"
                          label="Resource Name"
                          type="text"
                          placeholder="Enter resource name to create" v-model="resourceCreate.txtResourceName">
              </base-input>
              <base-input class="col-sm-4"
                          label="Resource Code"
                          type="text"
                          placeholder="Enter resource code to create" v-model="resourceCreate.txtResourceCode">
              </base-input>
            </div>
            <div class="row">
              <base-input class="col-sm-6"
                          label="Phone number"
                          type="text"
                          placeholder="Phone Number" v-model="resourceCreate.txtPhoneNumber">
              </base-input>

              <base-input class="col-sm-6"
                          label="Email Address"
                          type="text"
                          placeholder="Email Address" v-model="resourceCreate.txtEmailAddress">
              </base-input>

            </div>
            <div class="row">

              <base-input class="col-sm-12"
                          label="Note"
                          type="text"
                          placeholder="Note" v-model="resourceCreate.txtNote">
              </base-input>
            </div>




            <base-button class="animation-on-hover" type="primary" block @click="addResource">Add Resource</base-button>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <card class="bg-info" title="Resource Branch Admin">
        <h4 class="card-text"> Use this panel to associate a resource to a branch</h4>
        <div class="row">
          <div class="col-md-12">
            <card title="All Branches">
              <div class="card-body">
                <el-table :data="branch.tableData">
                  <el-table-column min-width="50" type="index"></el-table-column>
                  <el-table-column min-width="170" prop="branchName" label="Name"></el-table-column>
                  <el-table-column min-width="100" prop="logicId" label="Branch ID">
                  </el-table-column>

                  <el-table-column min-width="350"
                                   header-align="center"
                                   label="Associate Resource to branch">
                    <div slot-scope="{row}" class="text-center">
                      <el-select class="select-info"
                                 placeholder="Select a resource"
                                 v-model="row.selectedResourceId">
                        <el-option class="select-warning" value="null" label="Select a Resource" key="null"></el-option>
                        <el-option v-for="option in allResources.tableData"
                                   class="select-info"
                                   :value="option.id"
                                   :label="option.resourceName"
                                   :key="option.id">
                        </el-option>
                      </el-select>
                      <base-button type="primary" size="sm" @click="saveResourceForBranch(row)">
                        Save
                      </base-button>
                    </div>
                  </el-table-column>


                  <el-table-column min-width="150"
                                   header-align="center"
                                   label="Actions">
                    <div slot-scope="{row}" class="text-center">

                      <el-tooltip content="Show"
                                  :open-delay="300"
                                  placement="top">
                        <base-button type="info" size="sm" @click="showResourcesForBranch(row)">
                          Show Resources
                        </base-button>
                      </el-tooltip>
                    </div>
                  </el-table-column>
                </el-table>
              </div>
            </card>
          </div>


        </div>
        <div class="row">
          <div class="col-md-12">
            <card title="Branch Resources" :sub-title="branchResources.selectedName">
              <div class="card-body">
                <el-table :data="branchResources.tableData">
                  <el-table-column min-width="50" type="index"></el-table-column>
                  <el-table-column min-width="150" prop="resourceCode" label="Resource Code"></el-table-column>
                  <el-table-column min-width="250" prop="resourceName" label="Name"></el-table-column>
                  <el-table-column min-width="250" prop="emailAddress" label="Email"></el-table-column>
                  <el-table-column min-width="250" prop="phoneNumber" label="Phone"></el-table-column>
                  <el-table-column min-width="100" prop="resourceLogicId" label="Resource Id"></el-table-column>
                  <el-table-column min-width="100" prop="resourceName" label="Remove Resource from Branch">
                    <div slot-scope="{row}" class="text-center">
                      <base-button type="danger" size="" @click="deleteResourceFromBranch(row)">
                        <i class="tim-icons icon-simple-remove"></i>
                      </base-button>
                    </div>
                  </el-table-column>

                </el-table>
              </div>
            </card>
          </div>
        </div>
      </card>
    </div>


    <modal :show.sync="modals.showUpdateResource"
           body-classes="p-0">
      <card type="secondary"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template slot="header">
          <h2 class="modal-title" id="exampleModalLabel">Update resource {{updateResourceModal.resourceName}} </h2>
        </template>
        <div>
          <!-- MAJOR TODO Turn add queue into component and pull in here -->

          <div class="card-body">
            <base-input label="Resource Code"
                        type="text"
                        placeholder="Enter resource code to update" v-model="updateResourceModal.resourceCode">
            </base-input>
            <base-input label="Resource Name"
                        type="text"
                        placeholder="Enter resource name to update" v-model="updateResourceModal.resourceName">
            </base-input>
            <base-input label="Phone Number"
                        type="text"
                        placeholder="Enter Phone Number to update" v-model="updateResourceModal.phoneNumber">
            </base-input>
            <base-input label="Email Address"
                        type="text"
                        placeholder="Enter Email Address to update" v-model="updateResourceModal.emailAddress">
            </base-input>
            <base-input label="Note"
                        type="text"
                        placeholder="Enter Note to update" v-model="updateResourceModal.note">
            </base-input>
            <base-checkbox class="" v-model="updateResourceModal.enabled">
              Enabled
            </base-checkbox>
            </div>


        </div>
        <template slot="footer">
          <base-button type="primary" round @click="updateResource">Save changes</base-button>
          <base-button type="warning" round @click="modals.showUpdateResource = false">Close</base-button>
        </template>
      </card>
    </modal>


  </div>
</template>

<script>
import {Option, Select, Table, TableColumn} from 'element-ui'
import {AXIOS} from "../../config/http-commons";
import {Modal, TabPane, Tabs} from 'src/components';

export default {
  name: "ResourceAdmin",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    TabPane,
    Tabs,
    Modal

  },
  data() {
    return {
      selectedBranch: null,
      resourceCreate: {
        txtAddResourceName: null,
        txtResourceCode: null,
        txtResourceName: null,
        txtResourceLogicId: -1,
        txtPhoneNumber: null,
        txtEmailAddress: null,
        txtNote: null,
        clientId: -1,
        enabled: false,
      },
      branch: {
        tableData: [
          {
            branchName: "Loading",
            branchProperties: "",
            resourceCode: null,
            resourceName: null,
            resourceLogicId: -1,
            phoneNumber: null,
            emailAddress: null,
            note: null,
            clientId: -1,
            enabled: false
          }
        ]
      },
      allResources: {
        tableData: [{
          id: -1,
          resourceCode: "Loading",
          resourceName: null,
          resourceLogicId: null,
          customJsonProperties: null,
          phoneNumber: null,
          emailAddress: null,
          note: null,
          clientId: -1,
          enabled: false,
        }]
      },
      branchResources: {
        selectedName: 'Please select a branch from above',
        selectedLogicId: -1,
        tableData: [{
          id: -1,
          resourceCode: "Loading",
          resourceName: null,
          resourceLogicId: null,
          customJsonProperties: null,
          phoneNumber: null,
          emailAddress: null,
          note: null,
          clientId: -1,
          enabled: false,
        }]
      },
      pressedValue: false, //TODO as anti spam
      modals: {showUpdateResource: false},
      updateResourceModal: {
        id: -1,
        name: "Loading",
        resourceCode: "Loading",
        resourceName: null,
        resourceLogicId: null,
        customJsonProperties: null,
        phoneNumber: null,
        emailAddress: null,
        note: null,
        clientId: -1,
        enabled: false,
      },
    }
  },
  methods: {
    updateTables() {
      AXIOS.get('/rest/branch').then(response => {
        this.branch.tableData = response.data;
      });
      AXIOS.get('/rest/resources/').then(response => {
        this.allResources.tableData = response.data;
        this.allResources.tableData.forEach(q => {
          q.selectedQueueId = -1;
        })
      });
    },
    showUpdateResourceModal(row) {
      this.modals.showUpdateResource = true
      this.updateResourceModal = row;
    },
    updateResource() {
      let resource = this.updateResourceModal;
      AXIOS.put('/rest/resources/' + resource.resourceLogicId, resource).then(r => {

        this.$notify({type: 'success', message: 'Resource Updated'});
        this.updateTables();
        this.modals.showUpdateResource = false;
        this.updateResourceModal = {};
      }).catch(err => {
        this.$notify({type: 'danger', message: 'Please contact administrator'});
      });


    },
    deleteResource(row) {
      console.debug("deleteResource: " + row)
      AXIOS.delete('/rest/resources/' + row.resourceLogicId).then(r => {
        this.updateTables();
      }).catch(err => {
        this.$notify({type: 'danger', message: 'Error'});
      });
    },
    addResource() {
      if (this.resourceCreate === '' || this.resourceCreate.txtResourceName == null) {
        this.$notify({type: 'warning', message: 'Please enter a resource name'});
        return;
      }

      let json = {
        resourceName: this.resourceCreate.txtResourceName,
        resourceCode: this.resourceCreate.txtResourceCode,
        resourceLogicId: this.resourceCreate.txtResourceLogicId,
        phoneNumber: this.resourceCreate.txtPhoneNumber,
        emailAddress: this.resourceCreate.txtEmailAddress,
        note: this.resourceCreate.txtNote,
      };
      console.debug(json)
      AXIOS.post("/rest/resources/", json).then(response => {
        this.updateTables();

      }).catch(error => {
        console.debug('Error adding resource, error:')
        console.debug(error)

        if (error.response.data.errorCodeMessage) {
          this.$notify({type: 'danger', message: error.response.data.errorCodeMessage});
        } else {
          this.$notify({type: 'danger', message: 'Error Creating Que  ue'});
        }
      });
    },
    showResourcesForBranch(row) {
      this.selectedBranch = row;
      AXIOS.get('/rest/branch/' + row.logicId).then(response => {
        this.branchResources.tableData = response.data.resources;
        this.branchResources.selectedName = row.branchName;
        this.branchResources.selectedLogicId = row.logicId;
      });
    },
    deleteResourceFromBranch(row) {
      console.log('Called deleteResourceFromBranch row:')
      console.log(row)
      console.log(this.branch)
      AXIOS.delete('/rest/branch/' + this.branchResources.selectedLogicId +  '/resource/' + row.resourceLogicId).then(response => {
        this.branchResources.tableData = response.data.resource;
        this.branchResources.selectedName = row.branchName;
      });
      this.updateTables();
      this.showResourcesForBranch(this.selectedBranch);

    },

    saveResourceForBranch(row) {
      let selectedResourceId = row.selectedResourceId;
      let ques = this.allResources.tableData;
      for (let i = 0; i < ques.length; i++) {
        let que = ques[i];
        if (que.id == selectedResourceId) {
          let body = JSON.stringify(que);
          AXIOS.post('/rest/branch/' + row.logicId + '/resource', body).then(response => {
            this.updateTables();
            this.showResourcesForBranch(row);
          });
          break;
        }
      }
    },
    booleanCellRenderer(row, column, cellValue) {
      return row[column.property] === true ? 'Yes' : 'No';
    }
  },
  created() {
    this.updateTables();
  }
}
</script>

<style scoped>

</style>
